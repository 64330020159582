import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../../styles/vars/colors.style';

export const ParticlesWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;

  ${({ fade }) => {
    if (fade) {
      return `
        :after {
          content: '';
          position: absolute;
          top: calc(100% - 6rem);
          bottom: 0;
          right: 0;
          left: 0;
          background: linear-gradient(
            to bottom,
            ${transparentize(1, colors.light)},
            ${colors.light}
          );
        }  
      `;
    }
  }}
`;

export const ParticlesCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
