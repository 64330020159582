import * as THREE from 'three';

import { ParticlesCanvas, ParticlesWrapper } from './index.style';
import React, { createRef } from 'react';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import PropTypes from 'prop-types';
import ResourceTracker from '../Map/ResourceTracker';
import Stats from 'three/examples/jsm/libs/stats.module';
import { colors } from '../../styles/vars/colors.style';
import gsap from 'gsap';
import { isMobile } from 'react-device-detect';
import { shuffle } from '../../utils/utils';

const dat = typeof window !== 'undefined' ? require('dat.gui') : null;

class Particles extends React.Component {
  constructor(props) {
    super(props);
    this.ref = {
      wrapper: createRef(),
      canvas: createRef(),
    };

    this.resTracker = new ResourceTracker();
    this.track = this.resTracker.track.bind(this.resTracker);

    this.debug = false;
    this.stats = null;
    this.imgTextures = null;
    this.textureLoader = new THREE.TextureLoader();
    this.particles = null;
    this.count = 0;
    this.sceneBackgroundColor = props.dark ? colors.humeBlack700 : colors.light;
    this.particle = {
      gap: props.gap,
      scale: props.scale,
      colors: props.dark
        ? [
            new THREE.Color(0x03a9f4),
            new THREE.Color(0xffc107),
            new THREE.Color(0x4caf50),
            new THREE.Color(0xa036f4),
            new THREE.Color(0xf44336),
            new THREE.Color(0x3f51b5),
            new THREE.Color(0x009688),
            new THREE.Color(0x673ab7),
            new THREE.Color(0xff9800),
            new THREE.Color(0xe91e63),
            new THREE.Color(0xffc1b4),
            new THREE.Color(0x5bd5c6),
          ]
        : [
            new THREE.Color(0x5bd5c6),
            new THREE.Color(0x63bb9c),
            new THREE.Color(0x6a9744),
            new THREE.Color(0x939a71),
            new THREE.Color(0x82730b),
            new THREE.Color(0xbb7739),
            new THREE.Color(0xad946a),
            new THREE.Color(0xb0ba7d),
            new THREE.Color(0xbdd4b0),
            new THREE.Color(0xc2ad70),
            new THREE.Color(0xd0c696),
            new THREE.Color(0xe8cfae),
          ],
    };
    this.particlesAmountX = 75;
    this.particlesAmountZ = props.zDepth;
    this.imagesAmountX = 3;
    this.imagesAmountZ = 3;
    this.imgWidth = 2;
    this.cameraLookAt = {
      xTarget: props.xTarget,
      yTarget: props.yTarget,
      y: props.y,
      z: props.z,
    };
    this.mouseX = 0;
    this.mouseY = 0;
    this.mouseMovement = {
      scale: 0.028,
      speed: 0.021,
    };

    if (typeof window !== `undefined`) {
      this.pixelRatioMatch = window.matchMedia(
        'screen and (min-resolution: 2dppx)',
      );
    }
  }

  componentDidMount() {
    if (this.props.images) {
      const imagesTotal = this.imagesAmountX * this.imagesAmountZ;
      this.imgTextures = shuffle(this.props.images)
        .splice(0, imagesTotal * 2)
        .map((img) => {
          const texture = this.track(this.textureLoader.load(img.fixed.src));
          texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
          texture.repeat.set(0.5, 0.36);
          return texture;
        });
    }
    this.wrapper = this.props.useWindow ? window : this.ref.wrapper.current;
    this.init();
  }

  componentWillUnmount() {
    if (this.images) {
      this.images.length = 0;
    }
    if (this.controls) {
      this.controls.dispose();
    }
    this.resTracker.dispose();
    this.renderer.dispose();
    window.cancelAnimationFrame(this.raf);

    this.wrapper.removeEventListener('pointermove', this.onPointerMove);
    window.removeEventListener('resize', this.resizeHandler);

    try {
      this.pixelRatioMatch.removeEventListener('change', this.resizeHandler);
    } catch (error) {
      try {
        this.pixelRatioMatch.removeListener(this.resizeHandler);
      } catch (error2) {
        console.error(error2);
      }
    }
  }

  setStats() {
    if (typeof document !== 'undefined') {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }
  }

  setScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(this.sceneBackgroundColor);
    this.sceneTwo = new THREE.Scene();
  }

  getPixelRatio() {
    return Math.min(window.devicePixelRatio, 2);
  }

  setSizes() {
    this.sizes = {
      width: this.ref.wrapper.current.offsetWidth,
      height: this.ref.wrapper.current.offsetHeight,
    };

    this.resizeHandler = () => {
      // Update sizes
      this.sizes.width = this.ref.wrapper.current.offsetWidth;
      this.sizes.height = this.ref.wrapper.current.offsetHeight;

      // Update camera
      this.camera.aspect = this.sizes.width / this.sizes.height;
      this.camera.updateProjectionMatrix();

      // Update renderer
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(this.getPixelRatio());
    };

    window.addEventListener('resize', this.resizeHandler);

    try {
      this.pixelRatioMatch.addEventListener('change', this.resizeHandler);
    } catch (error) {
      try {
        this.pixelRatioMatch.addListener(this.resizeHandler);
      } catch (error2) {
        console.error(error2);
      }
    }
  }

  setLights() {
    this.ambientLight = new THREE.AmbientLight();
    this.ambientLight.color = new THREE.Color(0xffffff);
    this.ambientLight.intensity = 1;
    this.sceneTwo.add(this.ambientLight);
  }

  setGUI() {
    const gui = new dat.GUI({ width: 360 });
    // gui.close()

    const particles = gui.addFolder('Camera');
    particles.add(this.cameraLookAt, 'xTarget').min(-50).max(50).step(0.01);
    particles.add(this.cameraLookAt, 'yTarget').min(-50).max(50).step(0.01);
    particles.add(this.cameraLookAt, 'y').min(0).max(40).step(0.01);
    particles.add(this.camera.position, 'z').min(0).max(50).step(0.1);
    particles.add(this.particle, 'gap').min(0.2).max(5).step(0.01);
    particles.add(this.particle, 'scale').min(1).max(30).step(0.1);
    particles.open();

    // const mouseMovement = gui.addFolder('Mouse movement')
    // mouseMovement
    //   .add(this.mouseMovement, 'scale')
    //   .min(0.01)
    //   .max(0.1)
    //   .step(0.001)
    // mouseMovement
    //   .add(this.mouseMovement, 'speed')
    //   .min(0.005)
    //   .max(0.1)
    //   .step(0.001)
    // mouseMovement.open()
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(
      50,
      this.sizes.width / this.sizes.height,
      1,
      200,
    );
    this.camera.position.x = this.debug
      ? 0
      : (this.particlesAmountX * this.particle.gap) / 2;
    this.camera.position.y = this.cameraLookAt.y;
    this.camera.position.z = this.cameraLookAt.z;
    this.scene.add(this.camera);
  }

  setControls() {
    this.controls = new OrbitControls(this.camera, this.ref.canvas.current);
    this.controls.enableZoom = false;
  }

  setParticles() {
    const numParticles = this.particlesAmountX * this.particlesAmountZ;
    const positions = new Float32Array(numParticles * 3);
    const scales = new Float32Array(numParticles);
    const randomPositionValues = new Float32Array(numParticles);
    const randomColorValues = new Float32Array(numParticles);
    const xWidth = this.particlesAmountX * this.particle.gap;
    const zWidth = this.particlesAmountZ * this.particle.gap;
    let i = 0;
    let j = 0;

    for (let ix = 0; ix < this.particlesAmountX; ix++) {
      for (let iz = 0; iz < this.particlesAmountZ; iz++) {
        randomPositionValues[j] = gsap.utils.random(0, 1);
        randomColorValues[j] = THREE.MathUtils.randFloat(
          0,
          this.particle.colors.length - 1,
        );

        positions[i] =
          ix * this.particle.gap +
          this.particle.gap * (randomPositionValues[j] * 2 - 1) -
          xWidth / 2; // x
        positions[i + 1] = 0; // y
        positions[i + 2] =
          iz * this.particle.gap +
          this.particle.gap * gsap.utils.random(-1, 1) -
          zWidth +
          this.particle.gap * 20; // z

        scales[j] = 1 * this.getPixelRatio();

        i += 3;
        j++;
      }
    }

    const geometry = this.track(new THREE.BufferGeometry());
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
    geometry.setAttribute(
      'randomPosition',
      new THREE.BufferAttribute(randomPositionValues, 1),
    );
    geometry.setAttribute(
      'randomColorIndex',
      new THREE.BufferAttribute(randomColorValues, 1),
    );

    const material = this.track(
      new THREE.ShaderMaterial({
        uniforms: {
          colors: {
            value: this.particle.colors,
          },
          zWidth: {
            value: zWidth,
          },
        },
        transparent: true,
        vertexShader: `
        attribute float scale;
        attribute float randomColorIndex;
        varying float vColorIndex;
        varying vec4 pos;

        void main() {
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
          gl_PointSize = scale * ( 3.0 / - mvPosition.z );
          gl_Position = projectionMatrix * mvPosition;
          pos = gl_Position;
          vColorIndex = randomColorIndex;
        }
      `,
        fragmentShader: `
        uniform vec3 colors[12];
        uniform float zWidth;
        varying float vColorIndex;
        varying vec4 pos;

        void main() {
          if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;

          float zScale = pos.z / zWidth;
          
          int colorIndex = int(vColorIndex);
          vec3 color;
          
          if (colorIndex == 0) {
            color = colors[0];
          } else if (colorIndex == 1) {
            color = colors[1];
          } else if (colorIndex == 2) {
            color = colors[2];
          } else if (colorIndex == 3) {
            color = colors[3];
          } else if (colorIndex == 4) {
            color = colors[4];
          } else if (colorIndex == 5) {
            color = colors[5];
          } else if (colorIndex == 6) {
            color = colors[6];
          } else if (colorIndex == 7) {
            color = colors[7];
          } else if (colorIndex == 8) {
            color = colors[8];
          } else if (colorIndex == 9) {
            color = colors[9];
          } else if (colorIndex == 10) {
            color = colors[10];
          } else if (colorIndex == 11) {
            color = colors[11];
          }

          gl_FragColor = vec4( color, zScale * 6.0 );
        }
      `,
      }),
    );

    this.particles = this.track(new THREE.Points(geometry, material));
    this.scene.add(this.particles);
  }

  updateParticles() {
    const positions = this.particles.geometry.attributes.position.array;
    const scales = this.particles.geometry.attributes.scale.array;
    const rands = this.particles.geometry.attributes.randomPosition.array;
    const yRange = 0.6;
    const scaleRange = this.particle.scale * this.getPixelRatio();
    // const xWidth = this.particlesAmountX * this.particle.gap
    let i = 0;
    let j = 0;

    for (let ix = 0; ix < this.particlesAmountX; ix++) {
      for (let iy = 0; iy < this.particlesAmountZ; iy++) {
        // positions[i] =
        //   ix * this.particle.gap +
        //   this.particle.gap * (rands[j] * 2 - 1) -
        //   xWidth / 2

        positions[i + 1] =
          Math.sin((rands[j] * this.particlesAmountX + this.count) * 0.3) *
            yRange *
            rands[j] +
          Math.sin((rands[j] * this.particlesAmountZ + this.count) * 0.5) *
            yRange *
            rands[j];

        scales[j] =
          (Math.sin((ix + this.count) * 0.3) + 2) *
            scaleRange *
            (rands[j] / 2 + 0.5) +
          (Math.sin((iy + this.count) * 0.5) + 2.5) *
            scaleRange *
            (rands[j] / 2 + 0.5);

        i += 3;
        j++;
      }
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
    this.particles.geometry.attributes.scale.needsUpdate = true;
  }

  getImageGeometry() {
    const imgHeight = this.imgWidth * (413 / 300);
    const shape = new THREE.Shape();
    const shapeX = 0;
    const shapeY = 0;
    const borderRadius = 0.2;

    shape.moveTo(shapeX, shapeY + borderRadius);
    shape.lineTo(shapeX, shapeY + imgHeight - borderRadius);
    shape.quadraticCurveTo(
      shapeX,
      shapeY + imgHeight,
      shapeX + borderRadius,
      shapeY + imgHeight,
    );
    shape.lineTo(shapeX + this.imgWidth - borderRadius, shapeY + imgHeight);
    shape.quadraticCurveTo(
      shapeX + this.imgWidth,
      shapeY + imgHeight,
      shapeX + this.imgWidth,
      shapeY + imgHeight - borderRadius,
    );
    shape.lineTo(shapeX + this.imgWidth, shapeY + borderRadius);
    shape.quadraticCurveTo(
      shapeX + this.imgWidth,
      shapeY,
      shapeX + this.imgWidth - borderRadius,
      shapeY,
    );
    shape.lineTo(shapeX + borderRadius, shapeY);
    shape.quadraticCurveTo(shapeX, shapeY, shapeX, shapeY + borderRadius);

    return this.track(new THREE.ShapeBufferGeometry(shape));
  }

  getRandomImageTexture() {
    const randomTextureIndex = gsap.utils.random(
      0,
      this.imgTextures.length - 1,
      1,
    );
    const texture = this.imgTextures[randomTextureIndex];
    this.imgTextures.splice(randomTextureIndex, 1);
    return texture;
  }

  setImages() {
    const xGridSize = 11;
    const zGridSize = 8;
    const zWidth = (this.imagesAmountZ + 1) * zGridSize;
    this.images = [];

    const geometry = this.getImageGeometry();

    for (let ix = 0; ix < this.imagesAmountX; ix++) {
      for (let iz = 0; iz < this.imagesAmountZ; iz++) {
        const xOffset = iz % 2 === 0 ? 0 : xGridSize * 0.5;
        const x =
          ix * xGridSize -
          this.imgWidth / 2 +
          xOffset -
          xGridSize +
          gsap.utils.random(this.imgWidth * -0.25, this.imgWidth * 0.25);
        const y = 0.5 + gsap.utils.random(0, 0.75);
        const z =
          iz * -zGridSize +
          zGridSize -
          gsap.utils.random(-this.imgWidth * 0.25, this.imgWidth * 0.25);
        const overlayOpacity = 1 - gsap.utils.normalize(-zWidth, 0, z);
        const texture = this.getRandomImageTexture();
        const imgGroup = new THREE.Group();

        const imgMaterial = this.track(
          new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            opacity: 0,
          }),
        );
        const imgMesh = this.track(new THREE.Mesh(geometry, imgMaterial));
        imgMesh.position.set(x, y, z);
        imgGroup.add(imgMesh);

        const overlayMaterial = this.track(
          new THREE.MeshBasicMaterial({
            color: 0xfdf2e7,
            transparent: true,
            opacity: 0,
          }),
        );
        const overlay = this.track(new THREE.Mesh(geometry, overlayMaterial));
        overlay.position.set(x, y, z + 0.02);
        imgGroup.add(overlay);
        imgGroup.position.y = -0.8;

        this.sceneTwo.add(imgGroup);
        this.images.push({
          imgMaterial,
          overlayMaterial,
          overlayOpacity,
          mesh: imgGroup,
        });
      }
    }
  }

  animateImages() {
    this.images.forEach((img) => {
      const imgMaterial = img.imgMaterial;
      const overlayMaterial = img.overlayMaterial;
      const overlayOpacity = img.overlayOpacity;
      const mesh = img.mesh.position;

      const imgTl = gsap.timeline({
        delay: gsap.utils.random(1, 4),
        repeatDelay: gsap.utils.random(1, 3),
        repeat: -1,
        onRepeat: () => {
          const currentTexture = imgMaterial.map;
          imgMaterial.map = this.getRandomImageTexture();
          this.imgTextures.push(currentTexture);
        },
      });
      const exitDuration = 0.7;
      const enterDuration = 1;
      const openDuration = gsap.utils.random(5, 8);

      imgTl
        .to(imgMaterial, {
          opacity: '+=1',
          duration: enterDuration,
          ease: 'power3.out',
        })
        .to(
          overlayMaterial,
          {
            opacity: `+=${overlayOpacity}`,
            duration: enterDuration * 0.1,
            ease: 'power3.out',
          },
          0,
        )
        .to(
          mesh,
          {
            y: '+=0.8',
            duration: enterDuration,
            ease: 'power3.out',
          },
          0,
        )
        .addLabel('entered')
        .to(
          imgMaterial,
          {
            opacity: '-=1',
            duration: exitDuration,
            ease: 'power3.in',
          },
          `entered+=${openDuration}`,
        )
        .to(
          overlayMaterial,
          {
            opacity: `-=${overlayOpacity}`,
            duration: exitDuration * 1.2,
            ease: 'power3.in',
          },
          `entered+=${openDuration}`,
        )
        .to(
          mesh,
          {
            y: '-=0.8',
            duration: exitDuration,
            ease: 'power3.in',
          },
          `entered+=${openDuration}`,
        );
    });
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.ref.canvas.current,
      antialias: window.devicePixelRatio > 1 ? false : true,
    });
    this.renderer.autoClear = false;
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(this.getPixelRatio());
  }

  updateCamera() {
    this.camera.position.x +=
      (this.mouseX *
        (this.particlesAmountX * this.particle.gap * this.mouseMovement.scale) -
        this.camera.position.x) *
      this.mouseMovement.speed;

    this.camera.position.y +=
      (-this.mouseY * (50 * this.mouseMovement.scale) -
        this.camera.position.y +
        this.cameraLookAt.y) *
      this.mouseMovement.speed;

    this.camera.lookAt(this.cameraLookAt.xTarget, this.cameraLookAt.yTarget, 0);
  }

  handleUserEvents() {
    this.onPointerMove = (event) => {
      if (event.isPrimary === false) return;

      this.mouseX =
        gsap.utils.normalize(0, window.innerWidth, event.clientX) * 2 - 1;
      this.mouseY =
        gsap.utils.normalize(0, window.innerHeight, event.clientY) * 2 - 1;
    };

    this.wrapper.addEventListener('pointermove', this.onPointerMove);
  }

  animationLoop() {
    if (this.stats) {
      this.stats.update();
    }
    this.updateParticles();

    if (!this.debug) {
      this.updateCamera();
    } else {
      this.controls.update();
      this.camera.lookAt(
        this.cameraLookAt.xTarget,
        this.cameraLookAt.yTarget,
        0,
      );
    }

    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    this.renderer.clearDepth();
    this.renderer.render(this.sceneTwo, this.camera);

    this.count += 0.04;
    this.raf = window.requestAnimationFrame(this.animationLoop.bind(this));
  }

  init() {
    // this.setStats()
    this.setScene();
    this.setSizes();
    this.setCamera();
    this.setLights();
    this.setParticles();
    if (this.imgTextures) {
      this.setImages();
      this.animateImages();
    }
    if (this.debug) {
      this.setControls();
    }
    if (!isMobile) {
      this.handleUserEvents();
    }
    if (dat) {
      // this.setGUI()
    }
    this.setRenderer();
    this.raf = window.requestAnimationFrame(this.animationLoop.bind(this));
  }

  render() {
    const refs = this.ref;

    return (
      <>
        <ParticlesWrapper ref={refs.wrapper} fade={this.props.fade}>
          <ParticlesCanvas ref={refs.canvas} />
        </ParticlesWrapper>
      </>
    );
  }
}

export default Particles;

Particles.propTypes = {
  useWindow: PropTypes.bool,
  dark: PropTypes.bool,
  fade: PropTypes.bool,
  gap: PropTypes.number,
  scale: PropTypes.number,
  zDepth: PropTypes.number,
  xTarget: PropTypes.number,
  yTarget: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
};

Particles.defaultProps = {
  useWindow: true,
  dark: false,
  fade: true,
  gap: 1.4,
  scale: 7,
  zDepth: 75,
  xTarget: 0,
  yTarget: 7.53,
  y: 5.1,
  z: 27,
};
