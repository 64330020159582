import React, { useContext, useRef } from 'react';
import { ContextState } from 'components/Providers';
import Particles from '../Particles';
import { Heading2, Heading5, TextBody, TextBodySmall } from '../TextStyles';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import Spacer from '../Spacer/';
import IconArrowHeadRight from '../_svgs/IconArrowheadRight';

import {
  ContactWrapper,
  ContactMain,
  ContactRow,
  ContactEmail,
  ContactEmailLink,
  ContactEmailIcon,
  ContactEmailIconWrapper,
  ContactFooter,
  ContactParticles,
} from './index.style';
import Container from '../_layouts/Container';
import AnimateSplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

const Contact = ({ data }) => {
  const { title, address, emails, links } = data;
  const store = useContext(ContextState);
  const wrapperRef = useRef();

  return (
    <ContactWrapper ref={wrapperRef} aria-hidden={!store.contactIsOpen}>
      <ContactParticles>
        <Particles
          dark
          fade={false}
          gap={3.9}
          scale={5}
          xTarget={22}
          yTarget={-17.5}
          y={11.5}
          z={44.5}
          zDepth={30}
        />
      </ContactParticles>

      <ContactMain>
        <Container>
          <Grid>
            <GridItem tabletP={10} tabletPStart={4} deskL={4} deskLStart={10}>
              <Heading2 as="h1">
                <AnimateSplitText type={`lines`} heading={true}>
                  {title}
                </AnimateSplitText>
              </Heading2>
              <Spacer size={[24, 48]} />

              {React.Children.toArray(
                emails.map((email, emailIndex) => (
                  <ContactRow>
                    <AnimateFadeIn delay={(emailIndex + 1) * 0.4}>
                      <TextBody>{email.name}</TextBody>
                      <ContactEmail>
                        <ContactEmailLink href={`mailto:${email.emailAddress}`}>
                          <Heading5>{email.emailAddress}</Heading5>

                          <ContactEmailIconWrapper>
                            <ContactEmailIcon>
                              <IconArrowHeadRight responsive />
                            </ContactEmailIcon>
                            <ContactEmailIcon>
                              <IconArrowHeadRight responsive />
                            </ContactEmailIcon>
                          </ContactEmailIconWrapper>
                        </ContactEmailLink>
                      </ContactEmail>
                    </AnimateFadeIn>
                  </ContactRow>
                )),
              )}

              {React.Children.toArray(
                links.map((link, linkIndex) => (
                  <ContactRow>
                    <AnimateFadeIn
                      delay={(emails.length + 1 + linkIndex) * 0.4}
                    >
                      <TextBody>{link.name}</TextBody>
                      <ContactEmail>
                        <ContactEmailLink href={link.url} target="_blank">
                          <Heading5>{link.urlLabel}</Heading5>

                          <ContactEmailIconWrapper>
                            <ContactEmailIcon>
                              <IconArrowHeadRight responsive />
                            </ContactEmailIcon>
                            <ContactEmailIcon>
                              <IconArrowHeadRight responsive />
                            </ContactEmailIcon>
                          </ContactEmailIconWrapper>
                        </ContactEmailLink>
                      </ContactEmail>
                    </AnimateFadeIn>
                  </ContactRow>
                )),
              )}

              <Spacer size={[72, 120]} />

              <ContactFooter>
                <AnimateFadeIn delay={1.6}>
                  <Grid tabletP={10} deskL={4}>
                    <GridItem tiny={8} tabletP={5} deskL={2}>
                      <TextBodySmall>
                        © Hume {new Date().getFullYear()}
                        <br />
                        All rights reserved
                      </TextBodySmall>
                    </GridItem>

                    <GridItem tiny={8} tabletP={5} deskL={2}>
                      <TextBodySmall>{address}</TextBodySmall>
                    </GridItem>
                  </Grid>
                </AnimateFadeIn>
              </ContactFooter>
            </GridItem>
          </Grid>
        </Container>
      </ContactMain>
    </ContactWrapper>
  );
};

export default Contact;
