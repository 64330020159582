import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { transparentize } from 'polished';
import { zIndex } from '../../styles/vars/layout.style';
import { mq } from '../../styles/vars/media-queries.style';
import { easings } from '../../styles/vars/easings.style';

export const ContactWrapper = styled.div`
  position: relative;
  display: grid;
  padding-bottom: 10rem;
  padding-top: 10rem;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  place-items: center;
  z-index: ${zIndex.contact};
  overflow-x: hidden;
  overflow-y: scroll;
  color: ${colors.light};
  background-color: ${colors.humeBlack700};

  ${mq.tabletL} {
    padding-bottom: 0;
    padding-top: 6rem;
  }
`;

export const ContactParticles = styled.div`
  position: absolute;
  top: 83%;
  left: 0;
  bottom: 0;
  width: 100%;

  ${mq.tabletL} {
    top: 75%;
  }

  ${mq.deskL} {
    top: 0;
    width: 50%;

    :after {
      content: '';
      position: absolute;
      left: calc(100% - 6rem);
      bottom: 0;
      top: 0;
      right: 0;
      background: linear-gradient(
        to right,
        ${transparentize(1, colors.humeBlack700)},
        ${colors.humeBlack700}
      );
    }
  }
`;

export const ContactClose = styled.button`
  position: absolute;
  ${clamp('top', 26, 32)}
  ${clamp('right', 22, 50)}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  margin-top: -0.3rem;
  margin-right: -0.8rem;
  z-index: 30;
  color: ${colors.light};
  border-radius: 50%;
  border: 0.1rem solid ${colors.light};
  background-color: ${colors.humeBlack700};
`;

export const ContactMain = styled.div`
  width: 100%;
  ${clamp('padding-top', 24, 48)}
  ${clamp('padding-bottom', 24, 48)}
`;

export const ContactRow = styled.div`
  ${clamp('padding-top', 24, 48)}
  ${clamp('padding-bottom', 24, 48)}

  & + & {
    border-top: 0.1rem solid ${transparentize(0.8, colors.light)};
  }
`;

export const ContactEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.9rem;
`;
export const ContactEmailIconWrapper = styled.div`
  height: 1rem;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ${easings.buttonHover.hover};
  width: 3.9rem;
`;

export const ContactEmailIcon = styled.div`
  display: inline-block;
  will-change: transform;

  :nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateX(-110%);
  }
`;

export const ContactEmailLink = styled.a`
  align-items: center;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${mq.desk} {
    &:hover {
      ${ContactEmailIcon} {
        transition: transform 0.4s ${easings.buttonHover.hover};

        &:nth-child(1) {
          transform: translateX(100%);
        }

        &:nth-child(2) {
          transform: translateX(0);
          transition-delay: 0.1s;
        }
      }
    }
  }
`;

export const ContactFooter = styled.div`
  opacity: 0.5;

  ${mq.desk} {
    // padding-bottom: 10rem;
  }
`;
