import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Contact from '../components/Contact';
import ScrollSection from '../components/ScrollSection';

const ContactPage = (props) => {
  const {
    metaDescription,
    ...data
  } = props.data.allContentfulPageContact.nodes[0];

  return (
    <>
      <SEO title="Contact" description={metaDescription} pathname={`contact`} />
      <ScrollSection>
        <Contact data={data} />
      </ScrollSection>
    </>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query PageContact {
    allContentfulPageContact {
      nodes {
        metaDescription
        title
        address
        emails {
          emailAddress
          name
        }
        links {
          name
          url
          urlLabel
        }
      }
    }
  }
`;
